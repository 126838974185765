<template>
  <div id="sign-up">
    <Navbar class="dark-menu-icon" />

    <section>
      <h1>Sign Up with HFA/ Rumblefish</h1>
      <div class="cards">
        <div class="card" v-for="(card, index) in cards" :key="index">
          <a v-if="card.link" :href="card.link" target="_blank">
            <div class="type" v-html="card.type"></div>
            <div class="name">{{ card.name }}</div>
          </a>
          <a v-if="card.to" :href="card.to">
            <div class="type" v-html="card.type"></div>
            <div class="name">{{ card.name }}</div>
          </a>
          <a v-if="card.click" @click="card.click">
            <div class="type" v-html="card.type"></div>
            <div class="name">{{ card.name }}</div>
          </a>
        </div>
      </div>
    </section>

    <div class="background">
      <img src="@/assets/wave-footer.png" alt="background image" />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import HubspotForm from "@/components/HubspotForm.vue";

export default {
  name: "SignUp",
  components: {
    Navbar
  },
  metaInfo: function() {
    return {
      title: " - Sign Up",
      meta: [
        {
          name: "description",
          content: "Sign Up with HFA/ Rumblefish"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      cards: [
        {
          type: "Publishers",
          name: "Apply to Become an HFA Affiliate",
          to: "#/hfa-affiliate"
        },
        {
          type: "Music Distributors,<br>Digital Services & Apps",
          name: "Contact a Rumblefish Representative",
          click: this.showContactForm
        },
        {
          type: "Record Labels",
          name: "Create an HFA Licensing Account",
          to: "#/setup-hfa-portal"
        },
        {
          type: "Music Creators",
          name: "Create a Songfile Account to License Cover Songs",
          link: "http://www.songfile.com"
        }
      ]
    };
  },
  methods: {
    showContactForm: function() {
      this.$modal.show(
        HubspotForm,
        {},
        {
          height: "auto",
          width: "80%",
          classes: "v--modal contact-form-modal"
        }
      );
    }
  }
};
</script>

<style lang="scss">
#sign-up {
  position: relative;
  width: 100vw;
  margin-left: -8px;
  min-height: 100vh;

  #navbar {
    .logo {
      margin-left: 8px;
    }
    .mobile-menu {
      left: -8px;
    }
  }

  > .background {
    position: absolute;
    bottom: 0;
    width: 100vw;
    z-index: -1;

    img {
      width: 100%;
      transform: scale(-1, 1) translateY(5px);
    }
  }

  section {
    margin-top: 20px;

    h1 {
      font-size: 1.2em;
      font-family: "Fjalla One", sans-serif;
      font-weight: 400;
      text-align: center;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      width: 85%;
      margin: 0 auto;

      .card {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 22px 22px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 150px;
        margin-bottom: 20px;
        font-size: 0.85em;

        a {
          cursor: pointer;
        }

        .type {
          font-size: 1.75em;
          letter-spacing: 0.2px;
          text-align: center;
          font-family: "Fjalla One", sans-serif;
          margin-bottom: 10px;
        }
        .name {
          font-size: 1.375em;
          text-align: center;
        }
      }
    }
  }

  @media (min-width: $desktop) {
    margin: 0;
    width: auto;
    left: -50px;

    #navbar {
      margin-left: 50px;
    }

    section {
      margin-top: 90px;

      h1 {
        font-size: 2em;
        margin-top: 120px;
      }
      .cards {
        width: 55%;
        margin: 4vw auto 0;
        flex-direction: row;

        .card {
          padding: 5.5vw 0;
          font-size: 1em;
          width: 46%;
          min-height: auto;
          margin-bottom: 2vw;
        }
      }
    }
  }
}
</style>
